<template>
  <div class="inspect-layer">
    <!-- 导航提示标题 -->
    <!-- <ml-tip :showHeader="false" content="设备图层" /> -->
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip inspect-layer-tip" style="margin-bottom: 0">
      <div class="tmap-box" :style="{ height: `${tabberHeight(isFull ? 1 : 90)}px` }">
        <div class="tmap-box-main inspect-main">
          <t-amp
            id="inspectLayerId"
            ref="tMapRef"
            :center="tMapCenter"
            :list="inspectPoints"
            :showInspectStatus="showInspectStatus"
            @inspect-layer-click="inspectLayerClick"
          />

          <!-- 图例 -->
          <div
            class="inspect-tasks-legend"
            :class="{'inspect-tasks-legend-isTableClose': !isTableClose }"
          >
            <div class="lenged-title">图例</div>
            <div class v-for="(item, index) in legends" :key="'bar-' + index">
              <div class="check-box">
                <el-checkbox v-model="item.checked" label size="large" @change="filterPoint" />
              </div>
              <div class="image-box" :class="`${item.className}`">
                <img class="image" :src="item.icon" />
              </div>
              <div class="title">{{ item.title }}</div>
            </div>
          </div>

          <div class="table-box" :class="{ 'table-box-aside-isTableClose': isTableClose }">
            <!-- <i class="el-icon-close table-box-icon" @click="showTabel = false"></i> -->
            <div class="tmap-box-aside-close table-box-close" @click="isTableClose = !isTableClose">
              <div class="tmap-box-aside-close-btn"></div>
            </div>
            <div class="table-box-content" v-show="!isTableClose">
              <div class="table-box-content-title">
                <img :src="require('@image/overview.png')" alt="任务总览" /> 任务总览
              </div>
              <div class="table-box-content-filter">
                <el-row class="table-box-content-filter-item">
                  <el-col :span="6" class="table-box-content-filter-item-label">所属阿米巴：</el-col>
                  <el-col :span="18" class="table-box-content-filter-item-select">
                    <el-select v-model="ambSelected" placeholder="Select" placement="bottom">
                      <el-option
                        v-for="item in ambList"
                        :key="item.companyId"
                        :label="item.companyName"
                        :value="item.companyId"
                      />
                    </el-select>
                  </el-col>
                </el-row>
                <el-row class="table-box-content-filter-item">
                  <el-col :span="6" class="table-box-content-filter-item-label">所属项目：</el-col>
                  <el-col :span="18" class="table-box-content-filter-item-select">
                    <el-select v-model="projectSelected" placeholder="Select" placement="bottom">
                      <el-option
                        v-for="item in projectList"
                        :key="item.projectId"
                        :label="item.projectName"
                        :value="item.projectId"
                      />
                    </el-select>
                  </el-col>
                </el-row>
              </div>
              <div class="table-box-content-total">
                <el-row class="table-box-content-total-item">
                  <el-col :span="12" class="table-box-content-total-item-left">
                    <div class="total-image-box">
                      <img class="image" :src="require('@image/document.png')" />
                    </div>
                    <span>任务总数</span>
                    <span style="font-size:24px;font-weight:bold;">{{statistics.total}}</span>
                  </el-col>
                  <el-col :span="12" class="table-box-content-total-item-right">
                    <div style="margin-bottom:5px">
                      <span class="point">完成率</span>
                      <span style="float: right;">
                        <span style="font-size:18px;font-weight:bold;">{{statistics.rate*100}}</span>%
                      </span>
                    </div>
                    <el-progress
                      :percentage="statistics.rate*100"
                      color="#67C23A"
                      :show-text="false"
                    />
                  </el-col>
                </el-row>
              </div>
              <div class="table-box-content-list">
                <div class="table-box-content-list-header">
                  <div
                    v-for="item in typeMenus"
                    :key="item.type"
                    class="table-box-content-list-header-item"
                  >
                    <el-check-tag
                      :checked="activeType == item.type"
                      @click="activeType = item.type"
                    >{{item.name}}({{item.count}})</el-check-tag>
                  </div>
                </div>
                <div
                  class="table-box-content-list-content"
                  :style="{
                    height:isFull?'596px':'516px',
                  }"
                  v-infinite-scroll="loadMore"
                  infinite-scroll-immediate="false"
                  infinite-scroll-delay="500"
                >
                  <el-row v-for="item in inspectList" :key="item" class="scrollbar-item">
                    <el-col :span="16" class="scrollbar-item-left">
                      <div
                        class="scrollbar-item-left-title"
                        :class="'scrollbar-item-left-title-status'+item.status"
                        @click="inspectLayerClick(item)"
                      >{{item.taskName}}</div>
                      <div class="scrollbar-item-left-sub">{{item.inspectUserStr}}</div>
                    </el-col>
                    <el-col :span="8" class="scrollbar-item-right">
                      <template v-if="item.status==1">
                        <span style="color:#67C23A;font-weight:bold;">已完成</span>
                      </template>
                      <template v-if="item.status==2">
                        <span style="color:#67C23A;font-weight:bold;">已完成</span>
                        <span style="color:#999;">
                          超期
                          <span style="color:red;font-weight:bold;">{{item.timeout}}</span>
                          天
                        </span>
                      </template>
                      <template v-if="item.status==3">
                        <span style="color:#999;font-weight:bold;">未完成</span>
                        <span style="color:#999;">
                          超期
                          <span style="color:red;font-weight:bold;">{{item.timeout}}</span>
                          天
                        </span>
                      </template>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ml-tip>
  </div>
</template>

<script>
import { computed, inject, nextTick, onMounted, onBeforeUnmount, reactive, ref, watch, shallowRef } from 'vue'
import { tabberHeight, formetData, getTabberData, searchParams } from '@utils'
import { ElLoading } from 'element-plus'
import TAmp from '../../components/tamap.vue'
import { useStore } from 'vuex'
import { BASEURL, FILEUPLOADURL, FILEPREVIEWURL, tampCityLocaltionKey } from '@API'
import { useRouter } from 'vue-router'
import axios from 'axios'

export default {
  name: 'InspectLayer',
  components: {
    TAmp
  },
  setup(props, content) {
    const isFull = inject('isFull')

    const router = useRouter()
    const tMapCenter = ref([])
    const tMapRef = ref()
    const { commit, dispatch, getters } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)

    // 图例
    const legends = reactive([
      {
        icon: require('@/assets/image/map/项目.png'),
        title: '已完成任务',
        className: 'png-img',
        name: '1',
        checked: true
      },
      {
        icon: require('@/assets/image/map/项目-list.png'),
        title: '超期任务',
        className: 'png-img',
        name: '2',
        checked: true
      },
      {
        icon: require('@/assets/image/map/项目-g.png'),
        title: '未完成任务',
        className: 'png-img',
        name: '3',
        checked: true
      }
    ])
    // 地图点隐藏
    const showInspectStatus = ref(legends.filter(l => l.checked).map(l => l.name))
    // 图例勾选过滤数据
    const filterPoint = () => {
      showInspectStatus.value = legends.filter(l => l.checked).map(l => l.name)
    }

    // 过滤条件
    const ambSelected = ref('')
    const ambList = ref([])
    const projectSelected = ref('')
    const projectList = ref([])
    watch(
      () => ambSelected.value,
      async () => {
        // 更新项目选项
        await getProjectList(ambSelected.value)
        getInspectPoints({
          companyId: ambSelected.value,
          projectId: projectSelected.value
        })
        getInspectList({
          companyId: ambSelected.value,
          projectId: projectSelected.value,
          status: activeType.value
        })
      }
    )
    watch(
      () => projectSelected.value,
      () => {
        // 更新数据
        getInspectPoints({
          companyId: ambSelected.value,
          projectId: projectSelected.value
        })
        getInspectList({
          companyId: ambSelected.value,
          projectId: projectSelected.value,
          status: activeType.value
        })
      }
    )
    const getAmbList = async () => {
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      if (isBusinessDepartment.value) {
        ambList.value = [
          {
            companyId: '',
            companyName: '全部'
          },
          ...data
        ]
        ambSelected.value = ''
      } else {
        ambList.value = data
        ambSelected.value = data[0].companyId
      }
    }
    const getProjectList = async companyId => {
      const projectParams = { parent: 0 }
      if (companyId) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)

      projectList.value = [
        {
          projectId: '',
          projectName: '全部'
        },
        ...data
      ]
      projectSelected.value = ''
    }

    // 统计数据
    const statistics = ref({
      total: 0,
      rate: 0
    })
    // 巡检点数据
    const inspectPoints = shallowRef([])
    const getInspectPoints = async ({ companyId, projectId, status }) => {
      const { data } = await getTabberData(dispatch, 'fetchGetInspectTaskCount', {
        companyId,
        projectId,
        status
      })
      statistics.value = {
        total: data.total,
        rate: data.rate
      }
      typeMenus.value = [
        {
          name: '全部',
          count: data.total,
          type: ''
        },
        {
          name: '已完成',
          count: data.completeNum,
          type: '1'
        },
        {
          name: '未完成',
          count: data.noCompleteNum,
          type: '3'
        }
      ]
      inspectPoints.value = data.list.filter((o, i) => o.longitude & o.latitude)
    }
    //
    const inspectLayerClick = item => {
      const { inspectTaskId } = item
      if (inspectTaskId) {
        router.push({
          name: 'InspectTasksDetails',
          query: {
            inspectTaskId
          }
        })
      }
    }

    // 列表筛选
    const activeType = ref('')
    const typeMenus = ref([
      {
        name: '全部',
        count: 555,
        type: ''
      },
      {
        name: '已完成',
        count: 555,
        type: '1'
      },
      {
        name: '未完成',
        count: 555,
        type: '3'
      }
    ])
    watch(
      () => activeType.value,
      () => {
        getInspectList({
          companyId: ambSelected.value,
          projectId: projectSelected.value,
          status: activeType.value
        })
      }
    )

    // 列表数据
    let pageLoadIndex = 1
    const inspectList = ref([])
    const getInspectList = async ({
      companyId,
      projectId,
      status,
      pageIndex = 1,
      pageSize = 20
    }) => {
      const { data } = await getTabberData(dispatch, 'fetchGetInspectTaskPage', {
        companyId,
        projectId,
        status,
        pageIndex,
        pageSize
      })
      data.map(item => {
        item.inspectUserStr = item.inspectUserVos?.map(u => u.userName).join(',')
      })
      if (pageIndex == 1) {
        inspectList.value = data
        pageLoadIndex = 1
      } else {
        inspectList.value.push(...data)
      }
    }
    const loadMore = () => {
      getInspectList({
        companyId: ambSelected.value,
        projectId: projectSelected.value,
        status: activeType.value,
        pageIndex: ++pageLoadIndex
      })
    }

    // 收缩
    const isTableClose = ref(false)

    onMounted(async () => {
      let loading = ElLoading.service({
        lock: true,
        text: '',
        background: 'transparent'
      })

      await getAmbList()
      await getProjectList(ambSelected.value)
      await getInspectPoints({ companyId: ambSelected.value, projectId: projectSelected.value })
      await getInspectList({ companyId: ambSelected.value, projectId: projectSelected.value })
      // 关闭加载
      loading.close()
      loading = null
    })
    // 销毁前清除一些变量
    onBeforeUnmount(() => {})
    return {
      tabberHeight,
      isFull,
      tMapCenter,
      inspectPoints,
      legends,
      showInspectStatus,
      filterPoint,
      inspectLayerClick,
      isTableClose,
      ambSelected,
      ambList,
      projectSelected,
      projectList,
      activeType,
      statistics,
      typeMenus,
      inspectList,
      loadMore
    }
  }
}
</script>

<style lang="scss">
$table-width: 380px;
.inspect-layer {
  @extend %params-global;

  .el-tree {
    background: transparent;
  }

  &-tip {
    .el-card__body {
      padding: 0 !important;
    }
  }
}

.tmap-box-main.inspect-main {
  position: relative;

  .inspect-tasks-legend {
    position: absolute;
    min-width: 100px;
    top: 10px;
    right: 10px;
    z-index: 9999;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 13px;
    width: fit-content;
    align-items: center;
    justify-content: center;

    &-isTableClose {
      right: $table-width + 30px;
    }

    .lenged-title {
      margin: 0;
      padding: 5px;
      border-bottom: 1px solid #ddd;
      font-size: 16px;
      font-weight: bold;
    }
    > div {
      margin: 10px 5px;
      .check-box {
        display: inline-block;
      }
      .image-box {
        display: inline-block;
        width: 25px;
        height: auto;
        vertical-align: middle;
        margin-left: 5px;
        margin-right: 5px;

        .image {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        display: inline-block;
        text-align: center;
      }
    }
  }
}

.inspect-layer .table-box {
  position: absolute;
  right: 10px;
  top: 10px;
  bottom: 10px;
  // width: 1000px;
  // height: 100%;
  z-index: 1000;
  background-color: white;
  border-radius: 10px;
  &-close {
    left: -20px;
    right: inherit;
    background-image: url('~@image/biaoshi_tupian_w @3x.png') !important;
    opacity: 1 !important;
    transform: rotateZ(180deg);
    .tmap-box-aside-close-btn {
      background-image: url('~@image/jiantou_icon_w@3x.png') !important;
    }
  }

  &-aside-isTableClose {
    // right: -498px;
    .table-box-close {
      left: -10px;
    }
    .tmap-box-aside-close-btn {
      transform: rotate(180deg);
    }
  }
  &-content {
    width: $table-width;
    > div {
      border-bottom: 5px solid #eee;
      &:last-child {
        border-bottom: 0px solid #eee;
      }
    }
    &-title {
      padding: 10px;
      font-size: 20px;
      font-weight: bold;
      img {
        height: 25px;
        vertical-align: bottom;
      }
    }
    &-filter {
      &-item {
        margin: 10px;
        &-label {
          line-height: 40px;
          text-align: right;
        }
      }
    }
    &-total {
      // height: 80px;
      &-item {
        height: 80px;
        line-height: 80px;
        margin: 0 auto;
        &-left {
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            display: inline-block;
            height: 40px;
            line-height: 40px;
            margin: 0 3px;
            vertical-align: middle;
          }
          .total-image-box {
            display: inline-block;
            width: 25px;
            height: auto;
            vertical-align: middle;
            img {
              height: 25px;
              vertical-align: middle;
            }
          }
        }
        &-right {
          line-height: normal;
          padding: 25px 20px;
          padding-left: 0;
          .point {
            margin-left: 15px;
            &::before {
              content: '●';
              position: absolute;
              color: #67c23a;
              margin-left: -15px;
              font-size: 20px;
            }
          }
        }
      }
    }
    &-list {
      &-header {
        display: flex;
        border-bottom: 1px solid #eee;
        &-item {
          width: 33%;
          // height: 40px;
          // line-height: 40px;
          padding: 10px;
          text-align: center;
          box-sizing: border-box;
          > span {
            background: #fff;
            font-weight: normal;
            &.is-checked {
              font-weight: bold;
            }
          }
          .el-check-tag {
            padding: 7px;
          }
        }
      }
      &-content {
        // height: calc(100% - 500px);
        // height: 516px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 10px;
        .scrollbar-item {
          padding: 15px;
          padding-right: 5px;
          border-bottom: 1px solid rgb(238, 238, 238);
          &-left {
            padding-right: 8px;
            &-title {
              cursor: pointer;
              font-size: 16px;
              font-weight: bold;
              &::before {
                content: '●';
                position: absolute;
                color: red;
                margin-left: -15px;
                font-size: 20px;
              }
              &-status1::before {
                color: #67c23a;
              }
              &-status2::before {
                color: red;
              }
              &-status3::before {
                color: #999;
              }
            }
            &-sub {
              margin-top: 5px;
              color: #666;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          &-right {
            border-left: 1px solid #eee;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
        }
      }
    }
  }
}
</style>
